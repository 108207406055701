import React, { Component } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { colors, fonts, breakpoints } from '../../../style-utilities/variables';
import Default from './DefaultTab';
import DefaultNav from './DefaultNav';

const Div = styled.div`
margin: 3em 0;
  .slider-container {
    display: flex;
    overflow-x: hidden;
    min-height: 540px;
    @media (min-width: ${breakpoints.mobileMax}) {
      min-height: 700px;
      position: static;
      .tab-circle {
        display: none;
      }
      .hide {
        transform: none !important;
        transition: none !important;
      }
      .show {
        transform: none !important;
        transition: none !important;
      }
    }
    .show {
      transform: translate3d(90vw, 0, 0);
      transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
      .circle {
        display: none;
      }
    }
    .hide {
      transform: translate3d(8vw, 0, 0);
      transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
    }
    .off .circle {
      z-index: 0;
    }
  }
`;

class TabSlider extends Component {
  constructor(props) {
    super(props);
    this.sliderIndex = 1;
    this.state = {
      tabs: this.props.data[this.sliderIndex].default_slider,
      activeIndex: 0,
      visible: false
    };
  }

  highlightTab = idx => {
    this.setState({
      activeIndex: idx,
      visible: !this.state.visible
    });
  };
  closeTab = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  render() {
    // NOTE: This component was duplicated to use on the Solutions page/template only. 
    const { tabs, activeIndex, visible } = this.state;
    return (
      <Div>
        <div className="page-container">
          {this.props.data[this.sliderIndex].slider_headline ? (
            <h2>{this.props.data[this.sliderIndex].slider_headline}</h2>
          ) : (
            ''
          )}
        </div>
        <div className="slider-container">
          <DefaultNav
            data={this.props.data[this.sliderIndex].default_slider}
            activeIndex={activeIndex}
            visible={visible}
            highlightTab={this.highlightTab}
          />
          <Default
            tab={tabs[activeIndex]}
            activeIndex={activeIndex}
            visible={visible}
            type={this.props.type}
            closeTab={this.closeTab}
            bgcolor={this.props.bgcolor}
            data={this.props.data}
          />
        </div>
      </Div>
    );
  }
}

export default TabSlider;
