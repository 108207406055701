import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";

//components
import ButtonRed from "../components/ButtonRed";
import DefaultSliderSolutionsOne from "../components/TabSliders/DefaultSlider/DefaultSliderSolutionsOne";
import DefaultSliderSolutionsTwo from "../components/TabSliders/DefaultSlider/DefaultSliderSolutionsTwo";
import DefaultSliderSolutionsDynamic from "../components/TabSliders/DefaultSlider/DefaultSliderSolutionsDynamic";
import Hero from "../components/Hero";
import ContactBanner from "../components/ContactBanner";
import Related3Grid from "../components/Related/Related3Grid";
import SEO from "../components/SEO";
import DefaultSharingImage from "./../static/ansira-logo-sharing.png";

// ************************** //
//  Solutions PAGE CONTAINER:  //
// ************************** //
const StyledSolutions = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  overflow-x: hidden;
  .page-container {
    max-width: 1500px;
    margin: 0 auto;
  }
  .center-section {
    text-align: center;
    margin: 3rem 0;
    .copy {
      max-width: 780px;
      margin: 0 auto 20px;
    }
  }
  .img-column-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 20px;
    .column {
      width: 170px;
      a:hover {
        color: black;
      }
      img {
        width: 130px;
      }
    }
  }
  .bullet-container {
    margin-bottom: 3rem;

    a {
      color: ${colors.primaryGray};
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .center-section {
      margin: 10rem 0;
      .copy {
        margin: 0 auto 60px;
      }
    }

    .img-column-container {
      .column {
        width: 224px;
      }
    }

    .bullet-section {
      h2 {
        margin-bottom: 1em;
      }
      .bullet-container {
        margin-bottom: 120px;
        ul {
          width: 33%;
        }
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .img-column-container {
      justify-content: space-around;
    }
  }
`;

// Solutions LANDING PAGE

class Solutions extends Component {
  state = {
    isPreviewMode: false,
    previewPayload: null
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({
        loadTime: true,
        isBrowser: true,
        viewPort: window.innerWidth >= 768 ? "desktop" : "mobile"
      });
    }
    if (this.state.isBrowser) {
      window.addEventListener("resize", this.setViewport);
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get("preview")) {
      let id = params.get("id");
      let wpnonce = params.get("_wpnonce");
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    if (this.state.isPreviewMode) {
      page.title = this.state.previewPayload.title.rendered;
      page.content = this.state.previewPayload.content.rendered;
      page.acf = this.state.previewPayload.acf;
    }

    let page = this.props.data.wordpressPage;

    const arrayMixed = [];
    const arrayAll = [];

    this.props.data.allWordpressWpCaseStudy.edges.forEach(function(o) {
      arrayAll.push(o);
    });

    arrayAll.sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date);
    });

    arrayMixed.push(arrayAll[0].node);
    arrayMixed.push(arrayAll[1].node);
    arrayMixed.push(arrayAll[2].node);

    return (
      <StyledSolutions className="animate">
        {!this.props.isPreviewMode && (
          <SEO
            isBlogPost={false}
            postData={this.props.data.wordpressPage}
            postImage={DefaultSharingImage}
          />
        )}
        <Hero
          headline={page.acf.hero_headline}
          sub={page.acf.hero_sub_headline}
          textcolor={colors.white}
          bgcolor="black"
        />
        <div className="page-container">
          <p className="intro-copy">
            {ReactHtmlParser(page.acf.intro_copy)}
          </p>
        </div>


        {page.acf.create_slider.map((item, i) => {
           return ( <DefaultSliderSolutionsDynamic
             data={item.default_slider}
             headline={item.slider_headline}
             type={page.acf.slider_type}
             index={i}
             bgcolor="black"
           />
         )
        })}

        <div className="page-container">
          <div className="center-section">
            <h2>{page.acf.section_headline}</h2>
            <p className="copy">
              {ReactHtmlParser(page.acf.section_sub_headline)}
            </p>
            <div className="img-column-container">
              {page.acf.column_content.map((col, i) => {
                return (
                  <div className="column">
                    <Link to={`${col.column_link}`}>
                      <img src={col.column_image.source_url} />
                      <h4>{col.column_headline}</h4>
                      {col.column_copy ? (
                        <p className="copy">{col.column_copy}</p>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </div>
                );
              })}
            </div>
            {page.acf.section_cta || page.acf.section_cta_url ? (
              <ButtonRed
                textLabel={page.acf.section_cta}
                pageLink={page.acf.section_cta_url}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="bullet-section">
            <h2>{page.acf.bullet_list_headline}</h2>
            <div className="bullet-container">
              <ul>
                {page.acf.bullet_list_1.map((item, i) => {
                  if (item.list_item_url === "") {
                    return <li className="copy">{item.list_item}</li>;
                  } else {
                    return (
                      <Link to={item.list_item_url}>
                        <li className="copy">{item.list_item}</li>
                      </Link>
                    );
                  }
                })}
              </ul>
              <ul>
                {page.acf.bullet_list_2.map((item, i) => {
                  if (item.list_item_url === "") {
                    return <li className="copy">{item.list_item}</li>;
                  } else {
                    return (
                      <Link to={item.list_item_url}>
                        <li className="copy">{item.list_item}</li>
                      </Link>
                    );
                  }
                })}
              </ul>
              <ul>
                {page.acf.bullet_list_3.map((item, i) => {
                  if (item.list_item_url === "") {
                    return <li className="copy">{item.list_item}</li>;
                  } else {
                    return (
                      <Link to={item.list_item_url}>
                        <li className="copy">{item.list_item}</li>
                      </Link>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <Related3Grid
            header="The Proof Is in the Case Studies"
            items={arrayMixed}
            readAll="See All Case Studies"
            readAllLink="/results/case-studies/"
            gridCardType="minimal"
          />
          <ContactBanner
            headline={page.acf.contact_headline}
            cta={page.acf.contact_cta}
            url={page.acf.contact_url}
          />
        </div>
      </StyledSolutions>
    );
  }
}

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "solutions" }) {
      content
      slug
      title
      type
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        slider_type
        create_slider {
          slider_headline
          default_slider {
            slider_label
            slider_copy
            slider_cta_text
            slider_cta_url
          }
        }
        section_headline
        section_sub_headline
        section_cta
        section_cta_url
        column_content {
          column_image {
            source_url
          }
          column_headline
          column_link
        }
        bullet_list_headline
        bullet_list_1 {
          list_item
          list_item_url
        }
        bullet_list_2 {
          list_item
          list_item_url
        }
        bullet_list_3 {
          list_item
          list_item_url
        }
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          type
          topic
          slug
          date(formatString: "MMMM D, YYYY")
          author {
            name
            slug
          }
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressWpCaseStudy {
      edges {
        node {
          title
          type
          topic
          date(formatString: "MMMM D, YYYY")
          slug
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            client
            excerpt
          }
        }
      }
    }
  }
`;
export default Solutions;
